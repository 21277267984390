import React from "react"
import {Container, Row, Col} from 'react-bootstrap'
import Layout from "../../components/Layout/Layout"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import "./services.css"

const ServicesPage = () => {
    return (
  <Layout
    title="Our Services | Guiding Digital"
    description="Contact Guiding Digital today to learn how we can help with your SEO or web development needs in Northwest Arkansas and beyond." 
    metaUrl="https://www.guidingdigital.com/services/"
    twitterCardType="summary_large_image"
    twitterImage="https://www.guidingdigital.com/images/blog/2x1/our-services.png">
    <HeroBanner
      headline="Our Services"
      className="servicesPage"
    />

    <Container>
        <Row className="m-3 pb-5">
            <Col xs={12} md={6} className="d-none d-md-block">
                <StaticImage
                  src="../../images/seo-service2.png"
                  alt="SEO"
                  layout="fullWidth"
                  placeholder="blurred"
                  quality={90} />
            </Col>
            <Col xs={12} md={6}>
                <h2>Search Engine Optimization</h2>
                <div className="pb-4">
                    Already have a website and want to make sure it is optimized for search?
                    Contact us for a SEO audit and optimization plan.
                </div>
                <Link to="/services/seo/">Learn More About Our SEO Services &gt;</Link>
            </Col>
        </Row>
        <Row className="m-3 pb-5">
            <Col xs={12} md={6}>
                <h2>Web Development &amp; Hosting</h2>
                <div className="pb-4">
                    We work with you to create a site that is optimized from the start
                    to meet your business goals. We will enable you to update the site, or
                    we have content management plans available.
                </div>
            </Col>
            <Col xs={12} md={6} className="d-none d-md-block">
                <StaticImage
                  src="../../images/web-development-service2.png"
                  alt="Web Development &amp; Hosting"
                  layout="fullWidth"
                  placeholder="blurred"
                  quality={90} />
            </Col>
        </Row>
        <Row className="m-3 pb-5">
            <Col xs={12} md={6} className="d-none d-md-block">
                <StaticImage
                  src="../../images/developer-support-service2.png"
                  alt="Developer Support"
                  layout="fullWidth"
                  placeholder="blurred"
                  quality={90} />
            </Col>
            <Col xs={12} md={6}>
                <h2>Developer Support</h2>
                <div className="pb-4">
                    We're here to support developers through online training and content.
                    Visit our YouTube channel for our most recent content.
                </div>
            </Col>
        </Row>              

        
    </Container>

  </Layout>
    )
}

export default ServicesPage